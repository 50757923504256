<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/unit_user/reseau/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">编辑网格用户</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="网格" prop="name">
					<el-input size="mini" style="width: 300px;" disabled v-model="form.name"></el-input>
					<el-button class="ml-1" size="mini" @click="openDialog()">选择</el-button>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input type="textarea" v-model="form.phone"></el-input>
					<div class="pic-url-remark">
						多个手机号使用“，”分隔
					</div>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="网格列表" :visible.sync="dialogVisible" top="3vh" width="65%" height="60%">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="网格名称" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
				<div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
				</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" label="网格名称">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
						<template v-if="scope.row.second_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
						</template>
						<template v-if="scope.row.third_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<template v-if="scope.row.reseau_id == form.reseau_id">已选择</template>
						<el-button v-else type="text" size="mini" @click="selectReseau(scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				loading: false,
				dialogVisible: false,
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				search: {
					s: 'store/unit.reseau/index',
					keyword: '',
					page: 1,
					first_id: 0,
					second_id: 0,
					third_id: 0,
				},
				form: {
					name: '',
					reseau_id: 0,
					phone: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请选择网格',
						trigger: 'change'
					}, ],
					phone: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getUnitList()
			this.getList()
		},
		methods: {
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
			},
			selectReseau(item) {
				this.form.name = item.name;
				this.form.reseau_id = item.reseau_id;
				this.dialogVisible = false;
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			openDialog() {
				// 显示弹出层
				this.dialogVisible = true
			},

			getUnitList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.firstList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/unit_user.reseau/edit',
							},
							data: {
								user: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'unit_user.reseau/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
